/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */


:root {
    // --fc-border-color: #929397;
    --fc-daygrid-event-dot-width: 10px;


    --fc-button-bg-color: #8477ac;
    --fc-button-border-color: #8477ac;

}

// -------------- Calendar init --------------------

.fc .fc-button-primary:disabled {
    background-color: #1e293b;
    border-color: none;
    color: white;
}

.fc .fc-button-primary {
   
    border-color: none;
    color: white;
}

.fc-toolbar-title {
    font-size: 1.875rem;
    font-weight: 600;
    // color: #1E293B;
}



.fc-button-group {
    border-radius: 2.5rem;

}

 .fc-direction-ltr .fc-button-group>.fc-button:first-child {
     border-radius: 1rem 0 0 1rem;
     -webkit-border-radius: 1rem 0 0 1rem;
     -moz-border-radius: 1rem 0 0 1rem;
     -ms-border-radius: 1rem 0 0 1rem;
     -o-border-radius: 1rem 0 0 1rem;
}

.fc-direction-ltr .fc-button-group>.fc-button:last-child{
    border-radius: 0 1rem 1rem 0;
    -webkit-border-radius: 0 1rem 1rem 0;
    -moz-border-radius: 0 1rem 1rem 0;
    -ms-border-radius: 0 1rem 1rem 0;
    -o-border-radius: 0 1rem 1rem 0;
}

.fc-direction-ltr .fc-button-group>.fc-button{
    padding: .5rem 1.2rem;
}

// .fc-direction-ltr .fc-button-group>.fc-button:not(:last-child) {

//     border-radius: 1rem;
// }


// .fc-daygrid-event-dot{
//     // border-color: #a8dba8;
// }

.fc-event-title, .fc-event-time{
color: #1E293B;
}




// -------------- Calendar end --------------------



.timepicker-overlay {
    z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
    z-index: 10000000 !important;
}







